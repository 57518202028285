@import "../../base-styles.scss";

.contact {
  width: $standard-container-width;
  padding: 100px $standard-container-sidepadding;
  text-align: center;
  background-color: $tertiary-background;
  color: $tertiary-font-colour;
}

.contact--title {
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1.5em;
}

.contact--linkedintext {
  font-size: 17px;
  line-height: 1.5em;
}
