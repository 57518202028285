@import "../../base-styles.scss";

.home {
  background-image: url("../../images/hero-image-4.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $tertiary-background;
  text-align: center;
  color: $tertiary-font-colour;
  width: $standard-container-width;
  padding: 200px $standard-container-sidepadding 200px;

  @media (min-width: $desktop-min-breakpoint) {
    scroll-padding-top: 230px;
  }

  &--title {
    text-transform: uppercase;
    font-size: 30px;
    line-height: 1em;
    margin-bottom: 10px;
    font-weight: 600;
  }

  &--attributes {
    padding: 0;
    list-style-type: none;
    font-size: 16px;
    line-height: 1.9em;
  }

  &--attributes > li {
    @media (min-width: $desktop-min-breakpoint) {
      display: inline-block;
      &:after {
        content: "|";
        margin: 0 5px;
      }
    }
  }

  &--attributes > li:last-of-type {
    &:after {
      content: none;
      margin: none;
    }
  }

  &--overlay {
    width: 100%;
    height: 100px;
    margin-top: -100px;
    display: block;
    background: url("../../images/clouds.svg") no-repeat;
    background-size: cover;
  }
}
