@import "../../base-styles.scss";

.specialisms {
  width: $standard-container-width;
  padding: 50px $standard-container-sidepadding;
  background-color: $primary-background;
  line-height: 1.7em;

  &--title {
    display: inline-block;
    font-weight: 600;
    color: $primary-heading-colour;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 1em;

    &:after {
      display: block;
      margin-top: 10px;
      height: 4px;
      background-color: $primary-heading-colour;
      content: " ";
    }
  }

  &--description {
    @media (min-width: $desktop-min-breakpoint) {
      width: 65%;
    }
    padding-bottom: 40px;
  }

  &--list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &--listitem {
    padding: 30px 0 40px;
  }

  &--itemimage {
    @media (max-width: $mobile-max-breakpoint) {
      padding-bottom: 30px;
      margin: auto;
    }

    @media (min-width: $desktop-min-breakpoint) {
      display: inline-block;
      vertical-align: top;
      width: 25%;
      margin-right: 5%;

      img {
        margin: auto;
      }
    }
  }

  &--itemcontent {
    color: $primary-font-colour;
    @media (min-width: $desktop-min-breakpoint) {
      display: inline-block;
      width: 70%;
    }
  }

  &--itemtitle {
    font-weight: normal;
    font-size: 26px;
    color: $primary-heading-colour;
    text-transform: uppercase;
    margin: 0;
  }

  &--itemsubtitle {
    text-transform: uppercase;
    color: $primary-font-colour;
    line-height: 1.7em;
    font-weight: 500;
    font-size: 15px;
  }
}
