@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
/**
 * Add the correct display in IE.
 */
main {
  display: block;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}
/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}
/* User interaction
 * ========================================================================== */

body {
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
  font-size: 14px;
  color: #666;
  background-color: #fff;
  line-height: 1.7em;
  font-weight: 500;
  margin: 0;
  padding: 0;
}


.header{padding:20px 10%;width:80%;margin:0 auto;background-color:#fff;position:fixed;top:0}.header-shrunk{-webkit-animation:shrink 1s;animation:shrink 1s;padding-top:5px;padding-bottom:0}.header-expanded{-webkit-animation:expand 1s;animation:expand 1s;padding-top:20px;padding-bottom:20px}.header--logo{margin-left:5px;max-width:50%;cursor:pointer}.header--navicon{float:right;width:2em;height:2em;color:#0c71c3;display:none}@media (max-width: 980px){.header--navicon{display:block}}.header--navitem{font-size:14px;color:#000;text-decoration:none;letter-spacing:1px;cursor:pointer}@media (max-width: 980px){.header--nav{position:absolute;margin-top:25px;width:80%;background-color:#fff}.header--nav-open{-webkit-transform:translateX(0);transform:translateX(0)}.header--nav-closed{-webkit-transform:translateX(-120%);transform:translateX(-120%)}.header--nav-shrunk{margin-top:5px}.header--navitem{display:block;width:80%;padding:10px 10%;border-bottom:1px solid rgba(0,0,0,0.03)}.header--navitem-last{border-bottom:none}}@media (min-width: 981px){.header--nav{float:right;margin:20px 0 0}.header--navitem{text-transform:uppercase;padding:15px 5px;margin-right:12px}.header--navitem-last{margin-right:0}}@-webkit-keyframes shrink{0%{padding-top:20px;padding-bottom:20px}100%{padding-top:5px;padding-bottom:0}}@keyframes shrink{0%{padding-top:20px;padding-bottom:20px}100%{padding-top:5px;padding-bottom:0}}@-webkit-keyframes expand{0%{padding-top:5px;padding-bottom:0}100%{padding-top:20px;padding-bottom:20px}}@keyframes expand{0%{padding-top:5px;padding-bottom:0}100%{padding-top:20px;padding-bottom:20px}}

.home{background-image:url(/static/media/hero-image-4.1f1dc3ca.png);background-repeat:no-repeat;background-size:cover;background-color:#3274aa;text-align:center;color:#fff;width:80%;padding:200px 10% 200px}@media (min-width: 981px){.home{scroll-padding-top:230px}}.home--title{text-transform:uppercase;font-size:30px;line-height:1em;margin-bottom:10px;font-weight:600}.home--attributes{padding:0;list-style-type:none;font-size:16px;line-height:1.9em}@media (min-width: 981px){.home--attributes>li{display:inline-block}.home--attributes>li:after{content:"|";margin:0 5px}}.home--attributes>li:last-of-type:after{content:none;margin:none}.home--overlay{width:100%;height:100px;margin-top:-100px;display:block;background:url(/static/media/clouds.03ba641c.svg) no-repeat;background-size:cover}

.specialisms{width:80%;padding:50px 10%;background-color:#eaeaea;line-height:1.7em}.specialisms--title{display:inline-block;font-weight:600;color:#0c71c3;text-transform:uppercase;font-size:26px;line-height:1em}.specialisms--title:after{display:block;margin-top:10px;height:4px;background-color:#0c71c3;content:" "}.specialisms--description{padding-bottom:40px}@media (min-width: 981px){.specialisms--description{width:65%}}.specialisms--list{list-style:none;margin:0;padding:0}.specialisms--listitem{padding:30px 0 40px}@media (max-width: 980px){.specialisms--itemimage{padding-bottom:30px;margin:auto}}@media (min-width: 981px){.specialisms--itemimage{display:inline-block;vertical-align:top;width:25%;margin-right:5%}.specialisms--itemimage img{margin:auto}}.specialisms--itemcontent{color:#666}@media (min-width: 981px){.specialisms--itemcontent{display:inline-block;width:70%}}.specialisms--itemtitle{font-weight:normal;font-size:26px;color:#0c71c3;text-transform:uppercase;margin:0}.specialisms--itemsubtitle{text-transform:uppercase;color:#666;line-height:1.7em;font-weight:500;font-size:15px}

.casestudies{padding:50px 10%;width:80%;background-color:#000;color:#fff}.casestudies--title{display:inline-block;font-weight:600;color:#fff;text-transform:uppercase;font-size:26px;line-height:1em}.casestudies--title:after{display:block;margin-top:10px;height:4px;background-color:#0c71c3;content:" "}.casestudies .casestudy{padding:50px 0}.casestudies .casestudy--title{text-align:center;text-transform:uppercase;font-size:26px}

.contact{width:80%;padding:100px 10%;text-align:center;background-color:#3274aa;color:#fff}.contact--title{text-transform:uppercase;font-size:30px;line-height:1.5em}.contact--linkedintext{font-size:17px;line-height:1.5em}

.footer{width:80%;margin:0 auto;padding:20px 10%;color:#000;font-weight:500}.footer--company{font-size:12px;margin:0}.footer--location{font-size:12px;margin:0}.footer--address{font-size:12px;font-style:normal}@media (max-width: 980px){.footer{text-align:center}}

.totoptab{background:rgba(0,0,0,0.4);text-decoration:none;position:fixed;bottom:125px;right:0;border-top-left-radius:5px;border-bottom-left-radius:5px;cursor:pointer;font-size:30px;padding:5px;color:#fff;text-align:center;margin-right:-30px}.totoptab-visible{-webkit-animation:fadeIn 1s;animation:fadeIn 1s;margin-right:0}.totoptab-hidden{-webkit-animation:fadeOut 1s;animation:fadeOut 1s;margin-right:-30px}@-webkit-keyframes fadeIn{0%{margin-right:-30px}100%{margin-right:0}}@keyframes fadeIn{0%{margin-right:-30px}100%{margin-right:0}}@-webkit-keyframes fadeOut{0%{margin-right:0}100%{margin-right:-30px}}@keyframes fadeOut{0%{margin-right:0}100%{margin-right:-30px}}

