$primary-background: #eaeaea;
$secondary-background: #fff;
$tertiary-background: #3274aa;

$standard-container-width: 80%;
$standard-container-sidepadding: 10%;

$primary-heading-colour: #0c71c3;

$primary-font-colour: #666;
$secondary-font-colour: #000;
$tertiary-font-colour: #fff;

$mobile-max-breakpoint: 980px;
$desktop-min-breakpoint: 981px;
