@import "../../base-styles.scss";

.casestudies {
  padding: 50px $standard-container-sidepadding;
  width: $standard-container-width;
  background-color: #000;
  color: $tertiary-font-colour;

  &--title {
    display: inline-block;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 1em;

    &:after {
      display: block;
      margin-top: 10px;
      height: 4px;
      background-color: $primary-heading-colour;
      content: " ";
    }
  }

  .casestudy {
    padding: 50px 0;

    &--title {
      text-align: center;
      text-transform: uppercase;
      font-size: 26px;
    }
  }
}
