@import "../../base-styles.scss";

.header {
  padding: 20px $standard-container-sidepadding;
  width: $standard-container-width;
  margin: 0 auto;
  background-color: $secondary-background;
  position: fixed;
  top: 0;

  &-shrunk {
    animation: shrink 1s;
    padding-top: 5px;
    padding-bottom: 0;
  }

  &-expanded {
    animation: expand 1s;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &--logo {
    margin-left: 5px;
    max-width: 50%;
    cursor: pointer;
  }

  &--navicon {
    float: right;
    width: 2em;
    height: 2em;
    color: $primary-heading-colour;
    display: none;
  
    @media (max-width: 980px) {
      display: block;
    }
  }

  &--nav {
    &item {
      font-size: 14px;
      color: $secondary-font-colour;
      text-decoration: none;
      letter-spacing: 1px;
      cursor: pointer;
    }
  
    @media (max-width: $mobile-max-breakpoint) {
      position: absolute;
      margin-top: 25px;
      width: $standard-container-width;
      background-color: $secondary-background;
  
      &-open {
        transform: translateX(0);
      }
  
      &-closed {
        transform: translateX(-120%);
      }

      &-shrunk {
        margin-top: 5px;
      }
  
      &item {
        display: block;
        width: $standard-container-width;
        padding: 10px $standard-container-sidepadding;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  
        &-last {
          border-bottom: none;
        }
      }
    }
  
    @media (min-width: $desktop-min-breakpoint) {
      float: right;
      margin: 20px 0 0;
  
      &item {
        text-transform: uppercase;
        padding: 15px 5px;
        margin-right: 12px;
  
        &-last {
          margin-right: 0;
        }
      }
    }
  }
}

@keyframes shrink {
  0% {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  100% {
    padding-top: 5px;
    padding-bottom: 0;
  }
}

@keyframes expand {
  0% {
    padding-top: 5px;
    padding-bottom: 0;
  }
  100% {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
