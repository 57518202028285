.footer {
  width: 80%;
  // max-width: 1080px;
  margin: 0 auto;
  padding: 20px 10%;
  color: #000;
  font-weight: 500;

  &--company {
    font-size: 12px;
    margin: 0;
  }

  &--location {
    font-size: 12px;
    margin: 0;
  }

  &--address {
    font-size: 12px;
    font-style: normal;
  }

  @media (max-width: 980px) {
    text-align: center;
  }
}
