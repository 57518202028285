.totoptab {
  background: rgba(0, 0, 0, 0.4);
  text-decoration: none;
  position: fixed;
  bottom: 125px;
  right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  font-size: 30px;
  padding: 5px;
  color: #fff;
  text-align: center;
  margin-right: -30px;
  
  &-visible {
    animation: fadeIn 1s;
    margin-right: 0;
  }

  &-hidden {
    animation: fadeOut 1s;
    margin-right: -30px;
  }
}

@keyframes fadeIn {
  0% {
    margin-right: -30px;
  }
  100% {
    margin-right: 0;
  }
}

@keyframes fadeOut {
  0% {
    margin-right: 0;
  }
  100% {
    margin-right: -30px;
  }
}
